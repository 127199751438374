@tailwind base;
@tailwind components;
@tailwind utilities;

::placeholder
{
    font-style: italic;
    font-size: 12px;
}
td
{
    padding: 5px;
}